const MAX_FILE_SIZE = 52428800; // 50 MiB

function alertTemplate(errors) {
  var errorText = "";
  errors.forEach(e => {
    errorText += `<li><span class="text-monospace">${e}</span> is too large to upload</li>`;
  });
  return `<div class="alert alert-danger alert-dismissible fade show" role="alert">
    <strong>Your upload was not accepted for the following reasons:</strong>
    <ul>${errorText}</ul>
    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>`;
}

function handleFileSizeRestrictions(evt) {
  var tooBigFiles = [];
  const files = [...evt.currentTarget.files];

  files.forEach(currentFile => {
    if (currentFile.size > MAX_FILE_SIZE) {
      evt.currentTarget.value = "";
      tooBigFiles.push(currentFile.name);
    }
  });

  if (tooBigFiles.length > 0) {
    document.querySelector(".error-container").innerHTML = alertTemplate(
      tooBigFiles
    );
  }
}

document.addEventListener("DOMContentLoaded", function() {
  var uploadField = document.querySelector(".form-control-file");
  if (uploadField !== null) {
    uploadField.addEventListener("change", handleFileSizeRestrictions);
  }
});
